require('./src/styles/global.css');

const smoothscroll = require('smoothscroll-polyfill');

exports.shouldUpdateScroll = ({
  prevRouterProps: { location: prevLocation } = {},
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location;
  const scrollToTopRoutes = ['', 'blog', 'post', 'news', 'jobs','job'];
  const rootPath = pathname.split('/')[1];

  if (scrollToTopRoutes.indexOf(rootPath) !== -1) {
    // restore scroll in routing by team filter selection
    if (rootPath === 'jobs' && prevLocation?.pathname === pathname) {
      const savedPosition = getSavedScrollPosition(prevLocation);

      window.requestAnimationFrame(() => {
        window.scrollTo(...savedPosition);

        // sync stored scroll data
        if (prevLocation.key !== location.key) {
          sessionStorage.setItem(`@@scroll|${pathname}|${location.key}`, `${savedPosition[1]}`);
        }
      });

      return true;
    }

    // restore scroll when return to jobs page from job detail page
    if (rootPath === 'jobs' && prevLocation?.pathname.startsWith('/job/')) {
      const savedPosition = getSavedScrollPosition(location);

      window.requestAnimationFrame(() => {
        window.scrollTo(...savedPosition);
      });

      return true;
    }

    window.requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }

  return false;
};

exports.onClientEntry = () => {
  smoothscroll.polyfill();
};
