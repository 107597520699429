exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-index-tsx": () => import("./../../../src/pages/jobs/index.tsx" /* webpackChunkName: "component---src-pages-jobs-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-recruit-2022-biz-tsx": () => import("./../../../src/pages/recruit2022-biz.tsx" /* webpackChunkName: "component---src-pages-recruit-2022-biz-tsx" */),
  "component---src-template-job-index-tsx": () => import("./../../../src/template/job/index.tsx" /* webpackChunkName: "component---src-template-job-index-tsx" */),
  "component---src-template-post-index-tsx": () => import("./../../../src/template/post/index.tsx" /* webpackChunkName: "component---src-template-post-index-tsx" */)
}

